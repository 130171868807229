.section {
  width: 100%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Barlow, sans-serif;
  text-align: center;
  color: #353e52;

  h1 {
    margin: 10vh 0 0;
    font-family: Khand, sans-serif;
    font-size: 150px;
    font-weight: 400;
    color: #00bd97;
  }

  h2 {
    font-size: 1rem;
    color: rgb(0 0 0 / 60%);
  }

  p {
    font-size: 1.5rem;
    font-weight: 600;
    color: rgb(0 0 0 / 60%);
  }

  button {
    margin: 20px 0 40px;
    display: flex;
    align-items: center;
    border: none;
    font-family: Khand, sans-serif;
    font-size: 2rem;
    color: #00bd97;
    cursor: pointer;

    .seta {
      transform: rotate(180deg);
    }
  }
}
